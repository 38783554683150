<template>
    <label :class="classObject">
        <span class="vue-switcher__label" v-if="shouldShowLabel">
            <span v-if="label" v-text="label"></span>
            <span v-if="!label && value" v-text="textEnabled"></span>
            <span v-if="!label && !value" v-text="textDisabled"></span>
        </span>

        <input :id="id" type="checkbox" :disabled="disabled" @change="trigger" :checked="value">

        <div></div>
    </label>
</template>

<script>
    export default {
        name: 'LightSwitch',
        props: {
            id: {
                required: true,
            },
            typeBold: {
                default: false,
            },
            value: {
                default: false,
            },
            disabled: {
                default: false,
            },
            label: {
                default: '',
            },
            textEnabled: {
                default: '',
            },
            textDisabled: {
                default: '',
            },
            color: {
                default: 'default',
            },
            theme: {
                default: 'default',
            },
        },
        methods: {
            trigger (e) {
                // emit event to parent
                this.$emit('input', e.target.checked);
            },
        },
        computed: {
            checked () {
                return false;
            },
            classObject () {
                const {
                    color,
                    value,
                    theme,
                    typeBold,
                    disabled,
                } = this;

                return {
                    'vue-switcher': true,
                    'vue-switcher--unchecked': !value,
                    'vue-switcher--disabled': disabled,
                    'vue-switcher--bold': typeBold,
                    'vue-switcher--bold--unchecked': typeBold && !value,
                    [`vue-switcher-theme--${theme}`]: color,
                    [`vue-switcher-color--${color}`]: color,
                };
            },
            shouldShowLabel () {
                return this.label !== '' || this.textEnabled !== '' || this.textDisabled !== '';
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .vue-switcher {
        position: relative;
        display: inline-block;
        flex: 0 0 auto !important;

        &__label {
            display: block;
            font-size: rem(10);
            margin-bottom: rem(5);
        }

        input {
            @include square(100%);
            cursor: pointer;
            opacity: 0;
            position: absolute;
            z-index: 1;
        }

        div {
            align-items: center;
            border-radius: rem(30);
            cursor: pointer;
            display: flex;
            height: rem(16);
            justify-content: flex-start;
            position: relative;
            transition: linear .2s, background-color linear .2s;
            width: rem(36);

            &:after {
                @include square(rem(20));
                @include absolute(top rem(-3) left 100%);
                border-radius: rem(100);
                box-shadow: 0 rem(1) rem(5) 0 rgba(0, 0, 0, 0.1);
                content: '';
                cursor: pointer;
                display: block;
                margin-left: rem(-18);
                transition: linear .15s, background-color linear .15s;
            }
        }

        &--unchecked {
            div {
                justify-content: flex-end;

                &:after {
                    left: rem(15);
                }
            }
        }

        &--disabled {
            div {
                opacity: .3;
            }

            input {
                cursor: not-allowed;
            }
        }

        &--bold {
            div {
                top: 0;
                height: rem(26);
                width: rem(50);

                &:after {
                    margin-left: rem(-24);
                    top: rem(3);
                }
            }

            &--unchecked {
                div {
                    &:after {
                        left: rem(28);
                    }
                }
            }

            .vue-switcher__label {
                span {
                    padding-bottom: rem(7);
                    display: inline-block;
                }
            }
        }
    }

    .vue-switcher-theme--custom {
        &.vue-switcher-color--primary {
            div {
                background-color: $gray-lighter;

                &:after {
                    // for the circle on the switch
                    background-color: $brand-success;
                }
            }

            &.vue-switcher--unchecked {
                div {
                    background-color: $gray-lighter;

                    &:after {
                        background-color: $brand-primary;
                    }
                }
            }
        }
    }

</style>
