<template>
    <div class="o-container">
        <app-intro>
            <h1>Kies uw exterieur</h1>
            <h3>Welke opties zijn benodigd of gewenst?</h3>
        </app-intro>

        <loading v-bind:nodes="nodes">Bezig met laden van het exterieur...</loading>

        <transition-group class="c-exteriors" name="fade" tag="section">
            <card v-bind:card="node" :type="type" :index="index" v-for="node in nodes" :key="node.id" />
        </transition-group>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import AppIntro from '@/components/app/AppIntro.vue';
    import Card from '../components/cards/Card.vue';
    import Loading from '../components/Loading.vue';
    import setStartingValues from '../mixins/setStartingValues';

    export default {
        mixins: [setStartingValues],
        components: {
            Loading,
            Card,
            AppIntro,
        },
        data () {
            return {
                type: 'number',
                index: 'exterior',
            };
        },
        computed: {
            ...mapState({
                nodes: (state) => state.exterior.all,
            }),
        },
        async created () {
            this.$store.commit('tabs/setCurrentTab', this.$route.name);
            await this.$store.dispatch('exterior/getAll');
            this.setStartingValues();
        },
        beforeRouteEnter (to, from, next) {
            next((vm) => {
                const { tabs } = vm.$store.state;
                const isDisabled = tabs.tabs[tabs.currentTab].disabled;

                if (isDisabled)
                {
                    const index = 'exterior';
                    const nextTabs = vm.$store.getters['tabs/getNextTabsFromIndex'](index);
                    vm.$store.dispatch('tabs/disableTabs', nextTabs);
                    vm.$store.dispatch('summary/resetSummary', [index]);
                    next('/');
                }
                else
                {
                    next();
                }
            });
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../src/assets/scss/essentials";

    .c-exteriors {
        display: grid;
        grid-gap: rem(15);

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
        }

        > div {
            // IE

            $grid: 2;

            @for $i from 1 through $grid {
                &:nth-child(#{$grid}n + #{$i}) {
                    -ms-grid-column: $i;
                }
            }

            &:nth-child(1),
            &:nth-child(2) { -ms-grid-row: 1; }

            &:nth-child(3),
            &:nth-child(4) { -ms-grid-row: 2; }

            &:nth-child(5),
            &:nth-child(6) { -ms-grid-row: 3; }

            &:nth-child(7),
            &:nth-child(8) { -ms-grid-row: 4; }
        }
    }
</style>
