<template>
    <div>
        <div :class="'c-card c-' + index + ' c-' + type + (isSelected(index, card.id, card.checked) ? ' is-checked' : '') + (!card.is_available ? ' is-disabled' : '')" v-if="type !== 'number'">
            <label class="custom-control-label" :for="index + '-' + card.id">
                <input :type="type" class="custom-control-input" :id="index + '-' + card.id" name="card" :value="card.name" :checked="isSelected(index, card.id, card.checked)" @change="onChange($event, card)" :disabled="!card.is_available">
                <span class="custom-control-name">{{ card.name }}</span>
            </label>

            <router-link :to="'/detail/' + index + '/' + card.id" class="c-card__link" v-if="card.is_available">
                <svg class="c-card__link-svg" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                    <path class="c-card__link-bg" d="M7.5 0A7.5 7.5 0 1 1 0 7.5 7.5 7.5 0 0 1 7.5 0z"/>
                    <path class="c-card__link-border" d="M7.5 1A6.507 6.507 0 0 0 1 7.5C1 11.084 3.916 14 7.5 14S14 11.084 14 7.5 11.084 1 7.5 1m0-1a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15z" />
                    <text class="c-card__link-text" transform="translate(6 11)" font-size="10" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="0">i</tspan></text>
                </svg>
                <span class="u-hidden-sm-down">Lees meer</span>
            </router-link>
            <span class="c-card__link c-card__link--disabled || u-text-primary" v-else>Niet beschikbaar voor deze stijl</span>
        </div>

        <div :class="'c-card c-' + type" v-if="type === 'number' && !card.is_toggle">
            <label :for="index + '-' + card.id">{{ card.name }}</label>

            <input type="hidden" class="form-control" :id="index + '-' + card.id" min="0" :max="card.max" :value="getQuantity(index, card.id, 0)" @change="onChange($event, card)" />

            <div class="c-number__control">
                <a href="#0" class="c-toggle" :class="{ 'is-disabled': getQuantity(index, card.id, 0) === 0 }" data-min="0" :data-value="getQuantity(index, card.id, 0)" @click="decrease($event, card)">-</a>
                <div class="c-number__value || js-number-value">{{ getQuantity(index, card.id, 0) }}</div>
                <a href="#0" class="c-toggle" :class="{ 'is-disabled': getQuantity(index, card.id, 0) === card.max }" :data-max="card.max" :data-value="getQuantity(index, card.id, 0)" @click="increase($event, card)">+</a>
            </div>
        </div>

        <div :class="'c-card c-' + type + ' c-number--toggle'" v-if="type === 'number' && card.is_toggle">
            <label>{{ card.name }}</label>
            <light-switch v-model="checked" :id="index + '-' + card.id" type-bold="true" theme="custom" color="primary" @input="onSwitch($event, card)" />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import LightSwitch from '../LightSwitch.vue';

    export default {
        name: 'Card',
        components: {
            LightSwitch,
        },
        props: {
            card: {
                type: Object,
                required: true,
            },
            index: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters('summary', {
                isSelected: 'isSelected',
                getQuantity: 'getQuantity',
                summary: 'getSummaryByIndex',
            }),
            checked: {
                get () {
                    const exterior = this.summary('exterior').find((node) => node.id === this.card.id);
                    return typeof exterior !== 'undefined' ? exterior.quantity > 0 : false;
                },
                set (value) {
                    const { index } = this;
                    const payload = {
                        id: this.card.id,
                        quantity: value ? 1 : 0,
                    };
                    this.$store.commit('summary/updateQuantity', { payload, index });
                },
            },
        },
        methods: {
            increase (event, payload) {
                const { value } = event.target.dataset;

                // check if we are at the max
                if (Number(value) >= payload.max) return;

                // increase the quantity by 1
                payload.quantity = Number(value) + 1;

                // update quantity
                const { index } = this;
                this.$store.commit('summary/updateQuantity', { payload, index });
            },
            decrease (event, payload) {
                const { value } = event.target.dataset;

                // cannot go below 0
                if (Number(value) === 0) return;

                // decrease the quantity by 1
                payload.quantity = Number(value) - 1;

                // update quantity
                const { index } = this;
                this.$store.commit('summary/updateQuantity', { payload, index });
            },
            onSwitch (checked, payload) {
                payload.quantity = checked ? 1 : 0;

                // add the summary
                this.$store.dispatch('summary/addToSummary', {
                    payload,
                    index: this.index,
                    type: this.type,
                });

                // update tab
                this.$store.dispatch('tabs/updateTab', {
                    index: this.index,
                    type: this.type,
                });
            },
            onChange (event, payload) {
                if (typeof payload.is_available !== 'undefined' && !payload.is_available) return;
                if (Number(event.target.value) > payload.max) return;

                // add the value
                if (this.type === 'number')
                {
                    payload.quantity = parseInt(event.target.value, 10);
                }

                // reset all after "role"
                if (this.index === 'role')
                {
                    // disable next tabs
                    const nextTabs = this.$store.getters['tabs/getNextTabsFromIndex']('role');
                    this.$store.dispatch('tabs/disableTabs', nextTabs);

                    // reset next summary entries
                    this.$store.dispatch('summary/resetSummary', Object.values(nextTabs).map((tab) => tab.index));

                    // reset roles
                    this.resetRoles();
                }

                // add the summary
                this.$store.dispatch('summary/addToSummary', {
                    payload,
                    index: this.index,
                    type: this.type,
                });

                // update tab
                this.$store.dispatch('tabs/updateTab', {
                    index: this.index,
                    type: this.type,
                });
            },
            resetRoles () {
                for (const role of this.$store.state.summary.role)
                {
                    this.$store.dispatch('summary/updateSummary', {
                        payload: {
                            id: role.id,
                            quantity: 0,
                        },
                        index: 'role',
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .c-checkbox,
    .c-radio {
        padding: 0;
        border-width: rem(2);

        @include hover-focus {
            background-color: lighten($gray-lighter, 3);
        }

        .custom-control-label {
            padding: rem(30);

            @include media-breakpoint-up(lg) {
                padding: rem(40);
            }
        }

        .custom-control-name {
            &:before {
                background-color: $white;
            }
        }

        &.is-disabled {
            @include hover-focus {
                background-color: $white;
            }
        }

        // IE
    }

    .c-toggle {
        @include square(rem(30));
        @include flex-center;
        border: rem(1) solid $brand-primary;
        border-radius: 50%;
        color: $brand-primary;
        transition: $transition-base;

        @include hover-focus {
            background-color: $brand-primary;
            color: $white;
        }

        &.is-disabled {
            border-color: $text-muted;
            color: $text-muted;
            cursor: not-allowed;

            @include hover-focus {
                border-color: $text-muted;
                background-color: $white;
                color: $text-muted;
            }
        }
    }

    .c-number {
        align-items: center;
        display: flex;
        padding: rem(15);

        @include media-breakpoint-up(sm) {
            padding: rem(30);
        }

        label {
            font-size: rem(20);
            margin-bottom: 0;
            font-weight: $font-weight-black;
            flex: 1 1 auto;

            @include media-breakpoint-up(sm) {
                font-size: rem(30);
            }

            @include media-breakpoint-only(md) {
                font-size: rem(24);
            }
        }

        &__control {
            align-items: center;
            display: flex;
        }

        &__value {
            color: $brand-primary;
            font-size: rem(30);
            font-weight: $font-weight-black;
            padding: 0 rem(15);

            @include media-breakpoint-up(sm) {
                padding: 0 rem(30);
            }
        }

        &--toggle {
            padding-right: rem(40);

            @include media-breakpoint-up(sm) {
                padding-right: rem(75);
            }
        }
    }
</style>
